.video-container {

    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    background-color: black;
    box-shadow: 1px 3px 30px rgba(0,0,0,0.4); 
    
    video {
        width: 100%;
        opacity: 1;
        transition: opacity 1s ease-out, transform 300ms ease-out;
    }

    &.fade video {
        opacity: 0;
    }
}