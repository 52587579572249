.headline {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Oswald';
    font-weight: 800;
    font-size: 3em;
    color: #f0f0f0;
    margin-bottom: 64px;
}

.jambox {
    margin-bottom: 32px;
    width: 50%;
    margin: auto;
}

.jambox-link {
    display: flex;
    justify-content: center;
    margin: 64px;
    background: rgb(66,56,87);
    background: linear-gradient(90deg, rgba(66,56,87,1) 42%, rgba(231,143,91,1) 100%);
}

.jambox-info {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Oswald';
    font-weight: 800;
    font-size: 1.5em;
    color: #f0f0f0;
}

.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sampler {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 64px;
}

.video-background {
    // margin-bottom: 128px;
}

.sample {
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 45%;
}

@media (max-width : 1000px) {
    .headline {
        font-size: 1.75em;
    }
}

@media (max-width : 640px) {
    .headline {
        font-size: 1em;
    }
}