.loadingGradient {
    background: linear-gradient(90deg, #5ffdfd 0%, #529595 25%, #494a49 32%, #494a49 66%, #494a49 65%, #fc02fc 100%);
}

.headline {
    position: relative;
    display: flex;
    justify-content: center;    
    text-align: center;
    width: 100%;
    background-size: 100% auto;
    height: 40vw;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    box-shadow: 1px 3px 1.5em rgba(0,0,0,0.4); 
    background-image: url("../../assets/speeddial/splash.webp");

    .title {
        width: 35vw;
        height: auto;
    }

    .demo-link {
        font-family: "Press Start 2P", cursive;
        position: absolute;
        left: 50%;
        bottom: min(2em, 3vw);
        font-size: max(1em, 2vw);
        font-weight: 400;
        font-stretch: expanded;
        min-height: max(1em, 2vw);
        letter-spacing: 0.1em;
        transform: translateX(-50%);
        padding: 16px 16px;
        border: solid 3px white;
        background-color: rgb(0 0 0 / 50%);
        z-index: 10
    }
}

@media (max-width : 640px) {

    .title {
        margin-top: 32px;
        width: 75vw !important;
    }

    .headline {
        width: 100%;
        background-size: 200% auto;
        background-position: 50% 0;
        height: 80vw;

        
        .demo-link {
            font-size: 5vw;
            bottom: 5vw;
            padding: 2vw 4vw;
        }
    }   

    
}