.view {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 640px;

}

.content {
    width: 800px;
    margin: 0px auto;
    padding: 32px 0;
}

.content.headline {
    padding: 0 0 64px;
}


@media (max-width : 800px) {
    .content {
        width: 640px;
    }
}

@media (max-width : 640px) {
    .view {
        padding-bottom: 320px;
    }

    .content {
        width: unset;
        margin: 0 32px;

        &:not(.headline) {
            padding: 182px 0 64px 0 !important;
        }
    }
}