.footer {
    position: absolute;
    bottom: 0;
    left:0 ;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 640px;
    margin-top: -640px;
    overflow: hidden;
    padding-top: 32px;

    .background {
        width: 100%;
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
        border-bottom: 4px solid rgb(15,15,15);
    }

    .contents {   
        display: flex;
        font-family: 'Montserrat', sans-serif;
        background-color: rgb(15,15,15);
        width: 100%;
        height: 100%;
        flex-grow: 1;
        padding: 128px;
        z-index: 1;

        .headline {
            .title {
                font-size: 2em;
            }

            .subtitle {
                font-size: 1em;
                color: rgba(255,255,255,0.5)
            }
        }
    }

    .link {
        color: white; 
        padding: 0px 4px;
        font-family: 'Oswald', sans-serif;
    }

    .icon {
        display: flex;

        img {
            height: 2rem;
        }
    }

    .social {
        display: flex;
        gap: 8px;
        padding: 8px 0;
    }
}

@media (max-width : 640px) {
    .footer {
        height: 320px;
        margin-top: -320px;
    }

    .footer .contents {   
        padding: 64px 32px;
        justify-content: center;
        box-sizing: border-box;
    }
}