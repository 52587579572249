.view {
  background-image: url(../../assets/web_background.webp);
  background-attachment: fixed;
}

.most-wanted {
    display: flex;
    justify-content: center;

    .most-wanted-button {
        font-family: "Press Start 2P", cursive;
        margin: 32px;
        padding: 16px 24px;
        border: solid 3px gold;
        color: gold;
        text-decoration: dotted;
        text-transform: uppercase;
    }    
}

.scoreboard {
    margin: 128px;
    margin-top: 256px;
    display: flex;
    justify-content: center;

    .container {

        width: 512px;
        border: white solid 4px;
        font-family: "Press Start 2P", cursive;
        transform-style: preserve-3d;
        perspective-origin: 50% 50%;
        transform-origin: 50% 50%;

        .header-title {
            font-size: 1em;
            margin-top: 32px;
            margin-bottom: 8px;
            text-align: center;
        }

        .header { 
            margin-bottom: 32px;
            text-align: center;
            font-size: 4em;
            position: relative;

            .header-text {
                z-index: 100;
                position: relative;
                text-shadow: 0px 0px 16px rgba(150, 150, 150, 1);
            }
        }

        .police-tape {
            margin-top: 64px;
            filter: hue-rotate(-21deg);
            width: 100%;
        }

        .player {
            display: flex;
            justify-content: space-between;
            border-bottom: dashed 4px white;
            padding: 16px;
            background-color: rgba(0,0,0,0.5);
        }

        .player.top {
            color: gold;
        }

        .player.champ {
            z-index: 9999;
            background-color: gold;
            border: 4px dashed black;
            filter: drop-shadow(0 16px 32px gold);
            transform: translateY(calc(-192px));
            color: black;
        }

        .player:last-child {
            border-bottom: unset;
        }
    }

    
}


@media (max-width : 1000px) {
  .scoreboard { 
    margin: unset !important;
    margin-top: calc(64px + 32px) !important;
    width: unset;
  }
}

@media (max-width : 512px) {
    .scoreboard {

        
        .header {
            font-size: 15vw !important;
        }

        .container {
            width: 100% !important;
            border: unset !important;

            .player.champ {
              transform: translateY(calc(-40vw)) !important;
            }
        }
    }
}