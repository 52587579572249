.projects {
    padding: 128px 0 926px 0;
}

.projects-title {
    text-align: center;
    padding: 16px;
    font-size: 3rem;
    font-family: 'Oswald';
}


@media (max-width : 640px) {
    .projects {
        padding: 256px 0 512px 0;
    }
}
