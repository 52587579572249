.navbar {
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    padding: 16px;
    flex-direction: row;
    align-items: center;
    font-size: 1.5em;   
    box-sizing: border-box;
    z-index: 100;

    .logo {
        background-image: url("../assets/logos/eagleone.webp");
        background-size: contain;
        height: 3em;
        width: 3em;

        &:hover {
            background-image: url("../assets/logos/eagleone_lgbt.webp");
        }
    }

    .title {
        font-family: 'Montserrat', sans-serif;
    }

    .seperator {
        flex-grow: 15;
    }

    .link {
        color: white; 
        padding: 0px 4px;
        font-family: 'Oswald', sans-serif;
    }
    
    a { 
        color:white !important; 
        text-decoration: none;
    }

    a:hover {
        color: red !important;
    }

    .icon {
        display: flex;

        img {
            height: 1.5em;
        }
    }

    .group {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }
}

@media (max-width : 640px) {
    .navbar {
        flex-direction: column;
        padding: 2vw;

        .logo {
            height: 15vw;
            width: 15vw;
            margin-right: unset;
        }
        .link {
            padding: 0px 8px;
            font-size: min(5vw, 1em);
        }
    }

}