.title {
    font-family: "Press Start 2P", cursive;
    text-align: center;
    font-size: 1.25em;
    margin: 32px;
}

.most-wanted {
    display: flex;
    justify-content: center;

    .most-wanted-button {
        font-family: "Press Start 2P", cursive;
        margin: 32px;
        padding: 32px;
        border: solid 3px gold;
        color: gold;
        text-decoration: dotted;
        text-transform: uppercase;
    }    
}

.mapList {
    position: relative;
    z-index: 100;
    padding: 32px;
    padding-bottom: 112px;
    background-image: url("../../assets/speeddial/map_background.webp");
    background-size: 37% 50%;
    
    &:hover {
        -webkit-animation-name: shift; 
        -webkit-animation-duration: 30s; 
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -webkit-animation-direction: alternate;
    }

    img {
        max-width: 512px;
    }

    .title {
        font-family: "Press Start 2P", cursive;
        font-size: 3rem;
        text-align: center;
        color: white;
        border-radius: 16px;
        text-shadow: 0 0 20px #f54904;
        filter: drop-shadow(2px 4px 6px black);
    }


    .mapListContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 64px;
        justify-content: center;

        img {
            width: calc(33vw - 5vw);
            min-width: 256px;
        }
    }
}

.preview {
    position: relative;
    font-family: 'Oswald', sans-serif;
    text-align: center;

    &:not(.thumbnail) {
        box-shadow: 1px 3px 30px rgba(0,0,0,0.4); 
        display: flex;
    }

    &:not(.thumbnail) img {
        width: 100%;
    }

    iframe {
        margin-bottom: 32px;
    }

    &.thumbnail img {
        max-width: calc(100% - 64px);
    }

    &.thumbnail {
        // padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    a {
        width: 100%;
    }

    .videoWrapper {
        position: relative;
        padding-bottom: calc(56.25% / 1.5);
        height: 0;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0%;
        left: 50%;
        width: 66.667%;
        height: 100%;
        transform: translateX(-50%);
    }
}

.noun {
    font-style: italic;
}

.retro {
    font-family: 'Press Start 2P', cursive;
    font-size: 1em;
    padding: 128px 20%;

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-image: unset !important;
        border-left: unset !important;
    }

    img {
        width: 20vw;
        transform: translateX(-100%);
        position: absolute;
    }
}


@media (max-width : 640px) {
    .videoWrapper iframe {
        position: absolute;
        top: 0%;
        left: 50%;
        width: 100% !important;
        height: 100%;
        transform: unset;
    }
    .videoWrapper {
        padding-bottom: calc(56.25%) !important;
    }

    .preview:not(.thumbnail) {
        img {
            min-height: 80vw;
            object-fit: cover;
        }
    }

}


@media (max-width : 1000px) {
    .retro {
        padding: 32px 0;

        & > div {
            flex-direction: column-reverse;    
        }
        img {
            position: relative;
            width: 20vw;
            transform: unset;
        }
        
        text-align: center;
        font-size: 0.75rem;
    }    
}


@keyframes shift {
    0% {
        background-size: 37% 10%;
    }
    50% {
        background-size: 10% 100%;
    }
    100% {
        background-size: 0.01% 100%;
    }
}

@media (max-width : 640px) {
    .mapList img {
        max-width: 80vw;
    }
}
