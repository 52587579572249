.view {
  background-attachment: fixed;
}

.scoreboardlink {
    font-family: 'Poppins', sans-serif;
    padding: 16px;
    background-color: lightslategray;
    border-radius: 6px;
    font-weight: bolder;
    margin-bottom: 16px;
    margin-right: 8px;
}

.loader {
    margin: 16px;
    display: flex;
    justify-content: center;
}

.scoreboard {
    margin: 128px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .map-container {
        display: flex;
        justify-content: space-between;
    }

    .map-select {
        background-color: rgba(0,0,0,0.5);
        padding: 16px;
        font-family: 'Poppins';
        font-weight: 800;
        margin-right: 8px;
        border-radius: 6px;

        
        &.selected {
            background-image: linear-gradient(to right, #00f0ff, #42c3ff) !important;
        }
    }

    .header {
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        padding: 0 16px;
        justify-content: end;
        height: 48px;
        margin-bottom: 8px;
    }

    .container {

        width: 512px;
        font-family: "Poppins";
        padding: 24px 0;

        .title {
            text-align: center;
            font-weight: 900;
            text-transform: uppercase;
            background-color: rgba(0,0,0,0.6);
            font-size: 48px;
        }

        .map-title {
            text-align: center;
            font-weight: 100;
            margin-bottom: 8px;
            background-color: rgba(0,0,0,0.6);
            padding-bottom: 8px;
        }

        .player {
            display: flex;
            background-color: rgba(0,0,0,0.6);
            align-items: center;
            padding: 8px 16px;
            margin-bottom: 8px;
            background-image: linear-gradient(to right, #00f0ff, #42c3ff);
        }

        .top {
            color: black !important;
            a {
                color: black !important;
            }
            background-image: linear-gradient(to right, #fffb00, rgb(255, 208, 0));
        }

        .position {
            width: 32px;
        }

        .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 24px;
        }

        .time {
            flex-grow: 1;
            text-align: end;
        }

        .player:last-child {
            border-bottom: unset;
        }
    }

    
}


@media (max-width : 1000px) {
  .scoreboard { 
    margin: unset !important;
    margin-top: calc(128px) !important;
    width: unset;
  }
}

@media (max-width : 512px) {
    

    .scoreboard {
        .title {
            font-size: 10vw !important;
        }

        .container {
            width: 90% !important;
            border: unset !important;
        }
    }
}