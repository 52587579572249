
.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin: 32px 64px;
    margin-top: 0;

    .galleryItem {
        max-width: 512px;
        object-fit: contain;
        box-shadow: 1px 3px 1.5em rgb(0 0 0 / 40%);
        cursor: pointer;
    }

    .modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        z-index: 100;

        &.show {
            visibility: visible;
        }

        .arrowButton:first-of-type {
            transform: rotate(180deg);
        }
        
        .arrowButton {
            img {
                width: 50%;
                height: 50%;
            }

            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(48, 48, 48);
            border-radius: 50%;
            width: 5vw;
            height: 5vw;
            user-select: none;
            cursor: pointer;
        }

        .container {
            position: relative;
            box-shadow: 0 0 3em 13px rgb(0 0 0 / 56%);

            & > div:not(.close-button) + div:not(.close-button) {
                margin-top: 16px;
            }

            .close-button {
                display: flex;
                position: absolute;
                justify-content: center;
                align-items: center;
                top: 8px;
                right: 8px;
                width: 32px;
                height: 32px;
                font-size: 24px;
                font-weight: 900;
                cursor: pointer;
                padding: 8px;
            }

            .gallery-preview {
                width: 80vw;
                background-color: rgb(211, 211, 211);
                box-shadow: 1px 3px 1.5em rgb(0 0 0 / 40%);
            }
            
        }
    }
}


@media (max-width : 640px) {
    .gallery {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .galleryItem {
            max-width: 80vw;
        }
    }
}

@media (max-width: 1280px) {
    .modal {
        
        .container .gallery-preview {
            max-width: calc(100vw - 64px - 16px);
        }
        .arrowButton {
            min-width: 32px;
            min-height: 32px;
        }
    }
}