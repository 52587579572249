@import "../theme.scss";

.paragraph {
    display: flex;
    justify-content: center;
    width: 800px;

    width: 100%;
    padding: 64px 25%;
    box-sizing: border-box;
    color: $primary-text-color;
    font-size: 1.25em;
    line-height: 1.5;
    font-family: "Roboto",sans-serif;
    font-display: block;
    font-size: 1.5rem;

    &.grandprix {
        font-family: "Poppins";
    }

    &.white {
        background-color: #f1f1f1;
        color: $secondary-text-color;
        padding: 64px 25%;
    }

    &.alyx {
        & .contents {
            width: 800px;
            padding: 0 32px;
            margin: 0px 32px;
            border-left: rgba(0,0,0, 0.25) 1px solid;
            border-image: 
            linear-gradient(
            to bottom, 
            rgba(0, 0, 0, 0),
            #EF4946
            ) 0% 100%;
        }
    }

    .noun {
        font-style: italic;
    }

    b {
        font-weight: 600;
        line-height: 1.75;
    }
}

@media (max-width : 800px) {
    .paragraph .contents {
        width: unset;
        margin: 32px 16px;
        padding: 0 16px;
    }
}

@media (max-width : 640px) {
    .paragraph {
        font-size: 1.25rem;
    }
}