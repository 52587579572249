.title {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    font-size: 2em;
    margin: 32px;
}

.preview {
    position: relative;
    font-family: 'Oswald', sans-serif;
    color: rgb(200, 200, 200);
    text-align: center;

    &:not(.thumbnail) {
        box-shadow: 1px 3px 30px rgba(0,0,0,0.4); 
        display: flex;
    }

    &:not(.thumbnail) img {
        width: 100%;
    }

    iframe {
        margin-bottom: 32px;
    }

    &.thumbnail img {
        max-width: calc(100% - 64px);
    }

    &.thumbnail {
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    a {
        width: 100%;
    }


    .videoWrapper {
        position: relative;
        padding-bottom: calc(56.25% / 1.5); /* 16:9 */
        height: 0;
        margin-bottom: 32px;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0%;
        left: 50%;
        width: 66.667%;
        height: 100%;
        transform: translateX(-50%);
    }
}

.noun {
    font-style: italic;
}


@media (max-width : 640px) {
    .videoWrapper iframe {
        position: absolute;
        top: 0%;
        left: 50%;
        width: 100% !important;
        height: 100%;
        transform: unset;
    }
    .videoWrapper {
        padding-bottom: calc(56.25%) !important;
    }

    .preview:not(.thumbnail) {
        img {
            min-height: 80vw;
            object-fit: cover;
        }
    }

    .scanner {
        display: none;
    }
}

.scanner {
    position: absolute;
    transform: scale(0.5);
    filter: saturate(50%) hue-rotate(221deg);
    transform: translateY(0px) scale(0.5);
    z-index: 100;
    -webkit-animation-name: move; 
    -webkit-animation-duration: 15s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    &.rightBound {
    -webkit-animation-name: moveRight; 
        animation-direction: reverse;
        transform: scaleX(-1);
    }
}

@keyframes move {
    0% {
        transform: translateX(100vw) translateY(0px) scale(0.35);
    }
    10% {
        transform: translateX(80vw) translateY(32px) scale(0.35);
    }
    25% {
        transform: translateX(50vw) translateY(-32px) scale(0.35);
    }
    35% {
        transform: translateX(30vw) translateY(-64px) scale(0.35);
    }
    50% {
        transform: translateX(0vw) translateY(0px) scale(0.35);
    }
    100% {
        transform: translateX(-100vw) translateY(0px) scale(0.35);
    }
}

@keyframes moveRight {
    0% {
        transform: translateX(100vw) translateY(20vw) scale(0.35) scaleX(-1);
    }
    10% {
        transform: translateX(80vw) translateY(20vw) scale(0.35) scaleX(-1);
    }
    25% {
        transform: translateX(50vw) translateY(20vw) scale(0.35) scaleX(-1);
    }
    35% {
        transform: translateX(30vw) translateY(20vw) scale(0.35) scaleX(-1);
    }
    50% {
        transform: translateX(0vw) translateY(20vw) scale(0.35) scaleX(-1);
    }
    100% {
        transform: translateX(-100vw) translateY(20vw) scale(0.35) scaleX(-1);
    }
}
