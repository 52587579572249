.title {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 2em;
    margin: 32px;
}

.preview {
    position: relative;
    font-family: 'Poppins', sans-serif;
    background-color: rgb(200, 200, 200);
    text-align: center;
    padding: 32px;
    padding-top: 48px;

    iframe {
        margin-bottom: 32px;
    }

    &.thumbnail img {
        max-width: calc(100% - 64px);
    }

    &.thumbnail {
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    a {
        width: 100%;
    }

    .videoWrapper {
        position: relative;
        padding-bottom: calc(56.25% / 1.5);
        height: 0;
        margin-bottom: 32px;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0%;
        left: 50%;
        width: 66.667%;
        height: 100%;
        transform: translateX(-50%);
        border: solid 6px rgb(255 192 0);
    }
}

.scoreboardlink {
    font-family: 'Poppins', sans-serif;
    padding: 16px;
    background-color: lightslategray;
    border-radius: 6px;
    font-weight: bolder;
}

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 32px;
}

.cardriver {
    width: 512px;
}

.noun {
    font-style: italic;
}


@media (max-width : 640px) {
    .videoWrapper iframe {
        position: absolute;
        top: 0%;
        left: 50%;
        width: 100% !important;
        height: 100%;
        transform: unset;
    }
    .videoWrapper {
        padding-bottom: calc(56.25%) !important;
    }

    .preview:not(.thumbnail) {
        img {
            min-height: 80vw;
            object-fit: cover;
        }
    }
    
    .cardriver {
        width: 75%;
    }

    .intro {
        font-size: 1em;
    }
}