.module {
    z-index: 1000;
}

.content {
    display: flex;
    width: 90vw;
    max-height: 1000px;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 32px;
    padding-top: calc(128px + 64px);
    font-family: "Roboto",sans-serif;
}

.category {
    font-size: 1.33em;
    margin-bottom: 32px;
    max-width: calc(33vw - 12vw / 3);

    .title {
        white-space: nowrap;
        font-size: 2em;
        font-weight: 700;
        margin-bottom: 16px;
    }

    .membertitle {
        line-height: 2;
        cursor: pointer;
    }

    .membertitle:not(:last-child) {
        margin-bottom: 16px;
    }

    button {
        all: unset;
    }
}

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: 100;

    &.show {
        visibility: visible;
    }

    .container {
        position: relative;
        width: 600px;
        max-width: 600px;
        padding: 32px;
        margin: 16px;
        font-family: 'Roboto', sans-serif;
        background-color: #e3e3e3;
        border-radius: 2px;
        color: #1d1d1d;

        & > div:not(.close-button) + div:not(.close-button) {
            margin-top: 16px;
        }

        .membername {   
            display: table-caption;
            background-color: white;
            padding: 4px 18px;
            border-radius: 8px;
            font-size: 2em;
            font-weight: 600;
            white-space: nowrap;
        }

        .memberrole {
            white-space: nowrap;
            font-weight: 200;
            font-size: 0.7em;
        }

        .bio::before, .bio::after {
            font-family: "Roboto", sans-serif;
            font-style: italic;
            display: block;  
            font-size: 32px;
            color: #1d1d1d;
            font-weight: 800;
        }

        .bio::before {
            content: "\"";
            color: #F04944;
            font-size: 70px;
            margin-left: -26px;
        }


        .bio::after {
            content: "\"";
            color: #F04944;
            font-size: 70px; 
            transform: translateX(30px) translateY(4px) scale(-1);
        }

        .bio {
            font-size: 1.25em;
            background: white;
            padding: 32px 32px;
            line-height: 32px;
            border-radius: 8px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }

        .close-button {
            display: flex;
            position: absolute;
            justify-content: center;
            align-items: center;
            top: 8px;
            right: 8px;
            width: 32px;
            height: 32px;
            font-size: 24px;
            font-weight: 900;
            cursor: pointer;
            padding: 8px;
        }
    }
}

.infoGroup {
    display: inline-flex;
    background-color: white;
    padding: 9px 16px;
    border-radius: 8px;
    gap: 8px;
}

.socialLink {
    display:inherit;
    background-color: white;
    padding: 4px;

    &:hover {
        background-color: lightgray;
    }
}

@media (max-width : 1050px) {
    .content {    
        margin-left: auto !important;
        font-size: 13px;
    }
}

@media (max-width : 640px) {
    .content {    
        display: block !important;
        margin: 0 auto;
        margin-left: auto !important;
        max-height: unset !important;
        font-size: 16px !important;
        margin: auto !important;
    }
    .content .category {  
        font-size: 1.5em;
        text-align: center;
        max-width: unset;
    }
    .content .category:first-of-type {
        padding-top: 64px !important;
    }


    .modal {
        .close-button {
            display: block;
            left: 50%;
            top: 100% !important;
            background-color: rgba(227, 227, 227, 0.5);
            border-radius: 50%;
            padding: 16px;
            transform: translate(-50%, 50%)
        }

    }
}