.projects-title {
    text-align: center;
    font-size: 2.5em;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 32px;
}

.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;

    a {
        color: white;
        text-decoration: none;
    }
    .project {
        line-height: 1.5em;
        font-family: 'Roboto', sans-serif;
        text-align: center;
        box-shadow: 1px 3px 10px rgb(0 0 0 / 40%);
        padding: 16px;
        font-weight: bolder;
        font-size: 1.25em;
    
        img {
            width: 256px;
            height: 256px;
            background-image: url("../assets/loading.svg");
            background-size: 60% 60%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            margin-bottom: 16px;
        }
    
        .status {
            text-transform: uppercase;
            font-weight: 300;
        }
    }
}