@import "theme.scss";

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
    overflow-y: scroll;
}

body {
  margin: unset;
  padding: unset;
  background-color: $primary-color;
  color: white;
}


#app {
  min-height: 100%;
  position: relative;
}

a { 
  color:white !important; 
  text-decoration: none;
}